import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate, Link } from 'gatsby'

import Pagination from '../../components/blog/Pagination'
import Layout from '../../components/blog/Layout'
import PostCard from '../../components/blog/PostCard'
import MetaData from '../../components/common/MetaData'
import LocalizationContext, { LOCALES } from '../../components/LocalizationContext'
import Map from '../../components/common/Map'
import { tagsMappings } from '../../utils/tag'
import AdCard from '../../components/blog/AdCard'

import { default as styles } from './TagTemplate.module.css'

/**
* Tag page
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const TagTemplate = ({ data, location, pageContext }) => {
    const tag = data.ghostTag
    const posts = data.allGhostPost.edges
    const childrenTags = data.childrenGhostTags.edges
    const travelTags = data.travelGhostTags.edges

    if (tag == null) {
        useEffect(() => {
            navigate(`/404`)
        })

        return null
    }

    const childrenGrid = (
        <>
            {travelTags.length < 1 ? (
                <div className={styles.childrenGrid}>
                    {childrenTags.map(({ node: childrenTag }, i) => {
                        const mapping = tagsMappings.find(tagMapping => tagMapping.slug === childrenTag.slug)
                        const tagSlug = mapping != null
                            ? mapping.uri()
                            : childrenTag.slug

                        return (
                            <Link className={styles.childrenItem} to={tagSlug} key={i}>
                                <div className={styles.childrenItemImg} style={{ backgroundImage: `url(${childrenTag.feature_image})` }} />
                                {childrenTag.name}
                            </Link>
                        )
                    })}
                </div>
            ) : null}
        </>
    )

    return (
        <LocalizationContext.Provider value={{ locale: pageContext.language, localizedURL: pageContext.localizedUri }}>
            <MetaData
                data={data}
                location={location}
            />
            <Layout pathname={location.pathname}>
                <div className={styles.content}>
                    <header className={styles.header}>
                        <h1>{tag.name}</h1>
                        {tag.description ? <p>{tag.description}</p> : null }
                        {childrenGrid}
                        {travelTags.length > 0 ? (
                            <Map tags={travelTags.map(el => el.node)} currentTag={tag} />
                        ) : null}
                    </header>
                    <section className="post-feed">
                        {posts.map(({ node }, i) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <>
                                <PostCard key={node.id} post={node} />
                                {(i + 1) % 2 === 0 ? (
                                    <AdCard path={location.pathname} />
                                ) : null}
                            </>
                        ))}
                        {posts.length < 1 ? (
                            <div className={styles.fallback}>
                                {pageContext.language === LOCALES.FR ? (
                                    <>Il n&apos;y a aucun article dans cette catégorie</>
                                ) : (
                                    <>There is nothing to show here</>
                                )}
                            </div>
                        ) : null}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </LocalizationContext.Provider>
    )
}

TagTemplate.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        childrenGhostTags: PropTypes.object.isRequired,
        travelGhostTags: PropTypes.object.isRequired,
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default TagTemplate

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!, $childrenTags: [String!] = [], $travelTags: [String!] = []) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        childrenGhostTags: allGhostTag(filter: {slug: {in: $childrenTags}}) {
            edges {
                node {
                    ...GhostTagFields
                }
            }
        }
        travelGhostTags: allGhostTag(filter: {slug: {in: $travelTags}}) {
            edges {
                node {
                    ...GhostTagFields
                }
            }
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
