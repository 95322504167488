import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMap from 'google-map-react'

import { tagsData } from '../../../utils/nodes/tags/data'
import Marker from './Marker'

import { default as styles } from './Map.module.css'

export const Map = (props) => {
    const [openedBox, openBox] = useState(null)
    const { tags: ghostTags, currentTag } = props
    const tags = ghostTags
        .map((tag) => {
            const data = tagsData.find(data => data.slug === tag.slug)

            return {
                ...tag,
                gatsbySlug: data.uri(),
                position: data.position,
            }
        })
        .map((tag, i) => <Marker tag={tag} key={i} isOpen={openedBox === i} {...tag.position} />)
    const currentMapping = tagsData.find(data => data.slug === currentTag.slug)
    const defaultMapCenter = currentMapping.position != null
        ? currentMapping.position
        : { lat: 45, lng: 5 }
    const defaultMapZoom = currentMapping.position != null
        ? 11
        : 4

    return (
        <div className={styles.container}>
            <GoogleMap
                bootstrapURLKeys={{ key: `AIzaSyCWcyoKHHMa-__sMwbDxQmKJmz71BCDMhY` }}
                defaultCenter={defaultMapCenter}
                defaultZoom={defaultMapZoom}
                onChildClick={(key) => {
                    const id = Number(key)

                    if (openedBox === id) {
                        openBox(null)
                        return
                    }

                    openBox(id)
                }}
            >
                {tags}
            </GoogleMap>
        </div>
    )
}

Map.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentTag: PropTypes.object,
}
