import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../../LocalizationContext'

import { default as styles } from './Marker.module.css'

export function Marker(props) {
    const { tag, isOpen = false } = props
    const { locale } = useContext(LocalizationContext)

    if (tag.position == null) {
        return null
    }

    return (
        <>
            <div className={styles.container}>
                <div className={`${styles.pin} ${isOpen ? styles.pinOpened : ``}`} />
                <div className={`${styles.bubble} ${isOpen ? styles.bubbleOpened : ``}`} >
                    <h3 className={styles.title}>{tag.name}</h3>
                    {locale === LOCALES.FR ? (
                        <p className={styles.text}>Venez découvrir avec nous <Link to={tag.gatsbySlug}>cette belle ville</Link> !</p>
                    ) : null}
                    {locale === LOCALES.EN ? (
                        <p className={styles.text}>Come check out our posts on <Link to={tag.gatsbySlug}>this pretty city</Link>!</p>
                    ) : null}
                </div>
            </div>
        </>
    )
}

Marker.propTypes = {
    tag: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
}
